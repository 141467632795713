.container {
  font-weight: 400;
  width: 92%;
  max-width: 38rem;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding-top: 0;
  padding-bottom: 2rem;
}

@media print {
  .container {
    margin-right: 0;
  }
}
