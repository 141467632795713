html {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.55;
  color: #222;
}

@media screen and (min-width: 630px) {
  html {
    font-size: 18px;
  }
}

@media screen and (min-width: 960px) {
  html {
    font-size: 20px;
  }
}

body {
  margin: 0;
  padding: 0;
}

::selection {
  background: #004b5f;
  color: white;
  text-shadow: none;
}

@media print {
  html {
    print-color-adjust: exact;
    font-size: 10pt;
  }

  @page {
    size: A4;
    margin: 12mm 15mm 20mm 35mm;
  }
}
