a {
  text-decoration: none;
  color: #456ead;
}
a:hover {
  color: inherit;
}

@media print {
  a[href^="http"]:not(.print-no-href)::after {
    content: " (" attr(href) ")";
  }
}
