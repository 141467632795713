.list ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.list--li {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 3%;
  margin-bottom: 0.25rem;
}

@media print {
  .list--li {
    page-break-inside: avoid;
  }
}

.list--li::before {
  content: none;
  display: none;
}

.list--col_0 {
  display: block;
  grid-column-start: 1;
  grid-column-end: 5;
}

.list--col_0--inner {
  margin-top: 0.3em;
  font-size: 0.85em;
  line-height: 1.3;
  color: #004b5f;
  font-weight: 700;
  display: block;
}

.list--col_1 {
  display: block;
  grid-column-start: 5;
  grid-column-end: 13;
}

@media screen and (min-width: 630px) {
  .list--col_0 {
    grid-column-end: 4;
  }
  .list--col_1 {
    grid-column-start: 4;
  }
}
