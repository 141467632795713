ul,
ol {
  padding-left: 1em;
  margin: 0;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

ul {
  list-style: none;
}

li::before {
  content: "·";
  color: #004b5f;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}
