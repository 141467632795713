.avatar {
  margin-top: 1rem;
}

.avatar--img {
  width: 100%;
  height: auto;
  display: block;
}

.avatar--icon {
  width: 40%;
  height: auto;
  display: block;
}

.avatar--link {
  display: block;
}
