.skillevel__level {
  margin-top: 0.4rem;
  height: 0.5rem;
  width: 100%;
  background-color: #ddd;
  display: block;
}

.skillevel__level-inner {
  height: 0.5rem;
  display: block;
}

.skillevel__level-fill {
  transition: width 350ms;
  background-color: #004b5f;
  height: 0.5rem;
  display: block;
}

@media print {
  .skillevel__level-fill {
    width: 100% !important;
  }
}
